jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};
jQuery(document).ready(function ($) {  
    
    /*--------------------------------------------------------
	Header stick
    ---------------------------------------------------------*/
    var scroll = $(window).scrollTop();
    if (scroll > 80) {
        $('header').addClass('sticky-header');
    } else {
        $('header').removeClass('sticky-header');
    }
    $(window).scroll(function (event) {
        var scroll = $(window).scrollTop();
        if (scroll > 80) {
            $('header').addClass('sticky-header');
        } else {
            $('header').removeClass('sticky-header');
        }
    });

    var height = $('.header-fixed-wrap').outerHeight();
     
    if($(this).scrollTop() > height){
        $('.header-fixed-scroll-wrap').addClass('sticky');    
    }else{
        $('.header-fixed-scroll-wrap').removeClass('sticky');
    }       
    $(window).scroll(function () {
        var height = $('.header-fixed-wrap').outerHeight();
     
         if($(this).scrollTop() > height){
             $('.header-fixed-scroll-wrap').addClass('sticky');    
         }else{
             $('.header-fixed-scroll-wrap').removeClass('sticky');
         }       
    });
    
    /*--------------------------------------------------------
	Menu trigger
    ---------------------------------------------------------*/
    $('.menu-trigger').click(function () {
        $('html, body').toggleClass('open');
        $('.menu-trigger').toggleClass('open'); 
        $('.header-wrap').toggleClass('menu-open');
        $('.header-wrap').find('.mega-menu-wrap').find('ul').first().toggleClass('active-ul');
        if(!$('.header-wrap').hasClass('menu-open')){
            $('.header-wrap').find('.mega-menu-wrap').removeClass('open-submenu-1 open-submenu-2 open-submenu-3');
        }
    });

    // $('.header-wrap > ul >li.menu-item-has-children > a').click(function (e) {    
    //     let thisMenuItem = $(this);   
    //     if ($(window).width() < 1040) {   
    //        thisMenuItem.closest('li').toggleClass('opened');
    //        $('.li.menu-item-has-children').not(thisMenuItem).removeClass('opened');
    //     } else {
    //     //    thisMenuItem.closest('li').toggleClass('opened');
    //     //    thisMenuItem.closest('li').find('.sub-menu').slideToggle();
    //     }
    //     e.stopPropagation();
    //     e.preventDefault();
    // });
    // $('li.menu-item-has-children ul li ul li> a').click(function (e) {     
    //     if ($(window).width() < 1040) {   
    //         $('.header-wrap').slideUp();
    //         $('html, body').removeClass('open');
    //         $('.menu-trigger').removeClass('open');
    //     }
    // });

    $(".header-wrap").find("ul.mega-sub-menu").prepend('<li class="back-menu-button"><a href="#">Back</a></li>');
    $(".header-wrap").find("li.widget_text").closest('ul').find('.back-menu-button').addClass('have-menu-title');
    if ($(window).width() < 1040) {   
        $('.mega-menu-item-has-children > a, .menu-item-has-children > a').on('click', function(e){
            let parentUlLength = $(this).parents('ul').length;
            $(this).closest('.mega-menu-wrap').find('ul').not($(this).closest('li').find('ul').first()).removeClass('active-ul');
            $(this).closest('li').find('ul').first().addClass('active-ul');
            $(this).closest('.mega-menu-wrap').addClass('open-submenu-'+parentUlLength);
            // alert(l);
            e.preventDefault();
            e.stopPropagation();
        });
        
        $('.back-menu-button a').on('click', function(e){
            let parentUlLength = $(this).parents('ul').length - 1;
            $(this).closest('.mega-menu-wrap').removeClass('open-submenu-'+parentUlLength);
            setTimeout(() => {
                $(this).closest('ul.mega-sub-menu').parent('li').removeClass('mega-toggle-on');
            }, 700)
            e.preventDefault();
            e.stopPropagation();
        });
    }


    // Header call icon click
    $('.phone-contact .call-icon').on('click', function(){
        $(this).next().slideToggle(400);
    });
    $('.email-contact .email-icon').on('click', function(){
        $(this).prev().slideToggle(400);
    });

    /*--------------------------------------------------------
	Equal height
	---------------------------------------------------------*/
    if($('.eq-height').length){
        $('.eq-height').matchHeight({
            byRow: true,
            property: 'min-height',
            target: null,
            remove: false
        });
    }
    if($('.eq-height2').length){
        $('.eq-height2').matchHeight({
            byRow: true,
            property: 'min-height',
            target: null,
            remove: false
        });
    }

    if($('.eq-height3').length){
        $('.eq-height3').matchHeight({
            byRow: true,
            property: 'min-height',
            target: null,
            remove: false
        });
    }

    if($('.testimonial-eq-height').length){
        $('.testimonial-eq-height').matchHeight({
            byRow: false,
            property: 'min-height',
            target: null,
            remove: false
        });
    }
    if($('.testimonial-eq-height2').length){
        $('.testimonial-eq-height2').matchHeight({
            byRow: false,
            property: 'min-height',
            target: null,
            remove: false
        });
    }

   

    /*--------------------------------------------------------
	Readmore and Less
    ---------------------------------------------------------*/
    if($('a.read-more-content').length){
        $('a.read-more-content').on('click', function(e){
            var moreText = $(this).text();
            var lessText = $(this).data('less');
            $(this).toggleClass('will-less')
            $(this).closest('.show-more-content').find('.more-content').slideToggle(400);
            if($(this).hasClass('will-less')){
                $(this).text(lessText);
            } else {               
                $(this).text(moreText);
            }
            e.preventDefault();
        });
    }

    
    /*--------------------------------------------------------
	for loadmore section
    ---------------------------------------------------------*/
    if($('.team-row').length){
        $(".team-row-item").slice(0, 10).addClass('display');
        $(".load-more-btn .load-more").on('click', function (e) {
            e.preventDefault();
            $(".team-row-item:hidden").slice(0, 5).addClass('display');
            if ($(".team-row-item:hidden").length == 0) {
                $(".load-more-btn").remove();
            } else {
                $('html,body').animate({
                    scrollTop: $(this).offset().top
                }, 1500);
            }
        });
    }
    /*--------------------------------------------------------
	Price format
    ---------------------------------------------------------*/
    function numberWithCommas(number) {
        var parts = number.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    }
    $(document).ready(function() {
        $(".price-amount-format").each(function() {
            var num = $(this).text();
            var commaNum = numberWithCommas(num);
            $(this).text(commaNum);
        });
    });
    
    /*--------------------------------------------------------
        Readmore js
    ---------------------------------------------------------*/
	if($('.readmore-content').length){
		// $('.readmore-content').readmore({
		// 	speed: 400,
		// 	collapsedHeight: 460,
		// 	moreLink: '<a class="more-less-link more-link" href="#">Περισσότερα</a>',
		// 	lessLink: '<a class="more-less-link less-link" href="#">Λιγότερα</a>',
		// 	embedCSS: false,
		// });
        new Readmore('.readmore-content', {
            speed: 400,
			collapsedHeight: 460,
			moreLink: '<a class="more-less-link more-link" href="#">Περισσότερα</a>',
			lessLink: '<a class="more-less-link less-link" href="#">Λιγότερα</a>',
			embedCSS: false,
		});
	}


    $('li.mega-menu-item-has-children > a').wrapInner('<span class="menu-tab-span"></span>');

    /*--------------------------------------------------------
	Video
    ---------------------------------------------------------*/
    if ($('.player').length) {
        $('.player').each(function(index, element) {
            const player = new Plyr(element, {
                autoplay: false,
                loop: { active: true },
                controls: ['play-large'],
                hideControls: true,
                muted: true,
                clickToPlay: true
            });
            window.addEventListener('visibilitychange', () => {
                switch(document.visibilityState){
                    case 'hidden':
                        player.pause();
                        break;
                    // case 'visible':
                    //     player.play();
                    //     break;
                }
            })
        });
    }
    if ($('.ytplayer').length) {
        $('.ytplayer').each(function(index, element) {
            const ytplayer = new Plyr(element, {
                autoplay: false,
                loop: { active: true },
                controls: ['play-large'],
                hideControls: true,
                // muted: false,
                clickToPlay: true
            });
            window.addEventListener('visibilitychange', () => {
                switch(document.visibilityState){
                    case 'hidden':
                        ytplayer.pause();
                        break;
                    // case 'visible':
                    //     player.play();
                    //     break;
                }
            })
            
        });
    }
    
    // $('.destination-video-slider').on('beforeChange', function(event, { slideCount: count }, currentSlide, nextSlide){
    //     // alert('s');
    // });
    
    if ($('#player').length) {
        const player = new Plyr('#player', {
            autoplay: true,
            loop: { active: true },
            controls: ['none'],
            hideControls: true,    
            muted: true,
            clickToPlay: false   
        });
    }

    // Home page blocks video
   $('.play-video-wrap').each(function(index, element){
        const playDelay = 4000 + (3000 *  index);
        const homeVideo = $(this).find('.home-block-player');
        const homeBlockPlayer = new Plyr(homeVideo, {
            controls: ['play-large'],
            muted: true,
        });

        homeBlockPlayer.on('ready', (event) => {
            setTimeout(function() {
                homeBlockPlayer.muted = true;
                homeBlockPlayer.volume = 0;
                homeBlockPlayer.play();  
            }, playDelay); 
        });

    });
        

    // About page video
    if($('.about-video-player').length){
        const aboutPlayer = new Plyr('.about-video-player', {
            controls: ['play-large']
        });
        aboutPlayer.on('play', (event) => {
            $('.about-video-area').find('.about-video-headline').addClass('hide-headline');
        });
        aboutPlayer.on('pause', (event) => {
            $('.about-video-area').find('.about-video-headline').removeClass('hide-headline');
        });
        aboutPlayer.on('stopped', (event) => {
            $('.about-video-area').find('.about-video-headline').removeClass('hide-headline');
        });
    }

    
    /*--------------------------------------------------------
        Aos Animation
    ---------------------------------------------------------*/
    // AOS.init({
    //     once: true,
    //     delay: 0,
    //     duration: 1200,
    //     offset: 0,
    //     anchorPlacement: 'top-bottom',
    //     startEvent: 'load',
    // });
    // window.addEventListener('load', function() {
    //     AOS.refresh();
    // });

});

